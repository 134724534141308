<template>
  <button v-bind="buttonAttrs" class="flex items-center cursor-pointer bg-neutral-900/40 rounded-lg py-1.5 px-1.5 text-white">
    <span class="inline-block">
      <slot name="icon" />
    </span>
    <template v-if="$slots.text">
      <slot name="text" />
    </template>
  </button>
</template>
<script lang="ts" setup>
const props = defineProps({
  ariaHaspopup: {
    type: Boolean,
    default: false
  }
})
const buttonAttrs = computed(() => {
  const attrs: Record<string, any> = {}
  if (props.ariaHaspopup) {
    attrs['aria-haspopup'] = 'true'
  }
  return attrs
})
</script>
